import React, { useEffect } from 'react'

import About from '../components/AboutUsComponents/About';
import GearingUp from '../components/AboutUsComponents/GearingUp';
import Value from '../components/AboutUsComponents/Value';
import Chairman from '../assets/images/aboutus/chairman.jpg';

export default function Aboutus() {

  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <>
       
      <About />
      <GearingUp />
      <Value />
      <section className='aboutUsSec '>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <img src={Chairman} width="100%" className='rounded' alt="" />
            </div>
            <div className="col-lg-8">
              <h2>A Message from the School Chairman
              </h2>
              <p className='mt-4 px-2'>" Dear Students, Parents, and Staff,
                As we embark on a new academic year, I am honored to share with you the vision that will guide us forward on our journey of educational excellence. At SRI SPR HIGH SCHOOL, we believe in nurturing minds, inspiring hearts, and empowering futures.
              </p>
              <p className='px-2'>
                Our vision is rooted in the firm belief that every individual has the potential to excel and make a meaningful contribution to society. It is our collective responsibility to cultivate an environment where this potential can flourish, where curiosity is nurtured, and where students are encouraged to think critically, creatively, and compassionately.</p>
              <p className='px-2'>Central to our vision is the commitment to academic excellence. We strive to provide a rigorous and holistic education that equips our students with the knowledge, skills, and values necessary to succeed in an ever-changing world. Through innovative teaching practices, interdisciplinary learning experiences, and a focus on 21st-century competencies, we aim to inspire a lifelong love of learning and prepare our students to thrive in an increasingly complex global landscape.
                Equally important is our dedication to fostering a culture of inclusivity, respect, and empathy.
                We celebrate diversity in all its forms and recognize the unique strengths and talents that each individual brings to our community. By embracing diversity and promoting inclusivity, we create a welcoming and supportive environment where every member feels valued, heard, and empowered to reach their full potential.
                At the heart of our aim is a commitment to character development and ethical leadership. We believe in instilling in our students a strong sense of integrity, responsibility, and social conscience.
                Through service learning initiatives, leadership opportunities, and ethical
                decision-making, we aim to cultivate compassionate leaders who are committed to making a positive difference in their communities and beyond.
                As we embark on this journey together, let us remain steadfast in our commitment to our shared vision. Let us embrace the challenges and opportunities that lie ahead with courage, resilience, and optimism. Together, we will continue to inspire excellence, foster innovation, and empower the next generation of leaders."
                </p>
                <p className='mt-2 px-2'>Thank you for your unwavering support and dedication to our school's vision.</p>
                <p className='mt-2 px-2 highText'><b>Warm Regards,</b></p>
                <p className=' px-2 highText'><b>Punna Reddy Sonti Reddy</b></p>
                <p className='px-2 highText'><b>Chairman, Sri SPR Global School</b></p>
            </div>
          </div>


        </div>
      </section>
    </>
  )
}
