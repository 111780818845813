import React from 'react'
import { Link } from 'react-router-dom'
import spr1 from '../../assets/images/OurGallery/spr12.jpg'
import spr2 from '../../assets/images/OurGallery/spr11.jpg'
import spr3 from '../../assets/images/OurGallery/spr2.jpg'
import spr4 from '../../assets/images/OurGallery/spr4.jpg'
export default function Gallery() {
    return (
        <>
            <section className='gallerySec'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className='subHeading'>Sri SPR Global School</p>
                            <h2 className='pt-2'>Our School Gallery</h2>
                            <p>Explore our vibrant school life through our gallery. From classroom activities and sports events to cultural celebrations and field trips, see how our students learn, grow, and thrive every day. Scroll through the moments that make our school a wonderful place to be! </p>
                        </div>
                        <div className="col-12 mt-5">
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12 pt-3">
                                    <img src={spr1} className='allGalleryImg' width="100%" height="220px" alt="" />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12 pt-3">
                                    <img src={spr2} className='allGalleryImg' width="100%" height="220px" alt="" />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12 pt-3">
                                    <img src={spr3} className='allGalleryImg' width="100%" height="220px" alt="" />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12 pt-3">
                                    <img src={spr4} className='allGalleryImg' width="100%" height="220px" alt="" />
                                </div>
                            </div>
                            <div className="col-12 text-center mt-5">
                                <Link to='/gallery'>
                                    <button className='allBtns'>All Images</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
