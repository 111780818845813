import React, { useEffect, useState } from 'react'
import { postApi } from '../utilits/helpers/ApiServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contactus() {
  const [formData, setFormData] = useState({
    first_name: '',
    second_name: '',
    mobile_number: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  }

  const postData = async (data) => {
    try {
      const response = await postApi('/contact/create', data);
      setFormData({
        first_name: '',
        second_name: '',
        mobile_number: '',
        email: '',
        message: '',
      })
      let successMessage = "We will get back to you shortly, Thanks for reaching out!";
      toast.success(successMessage);
      console.log(response);
    } catch (error) {
      console.log('Error caught:', error);
      let errorMessage = 'An error occurred';
      if (error.response) {
        // Extracting the specific message from the server response
        if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        } else if (typeof error.response.data === 'string') {
          errorMessage = error.response.data;
        } else {
          errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
        }
      } else if (error.request) {
        errorMessage = 'No response received from server';
      } else {
        errorMessage = error.message;
      }

      toast.error(errorMessage);

    }
  }

  const contactForm = (e) => {
    e.preventDefault();
    const validationErrors = validate(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      postData(formData);
    }
  }

  const validate = (formData) => {
    const errors = {};
    if (!formData.first_name) {
      errors.first_name = "First name is required";
    }
    if (!formData.second_name) {
      errors.second_name = "Second name is required";
    }
    if (!formData.mobile_number) {
      errors.mobile_number = 'Please Enter the Phone Number';
    } else if (formData.mobile_number.length > 12 || formData.mobile_number.length < 10) {
      errors.mobile_number = 'Invalid Phone Number';
    }
    if (!formData.email) {
      errors.email = "Email is required";
    }
    if (!formData.message) {
      errors.message = "Enter your message";
    }
    return errors;
  };

  return (
    <>
      <section className='allFirstSections contactusSec '>
        <ToastContainer />
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6 col-md-5 col-sm-12 left_col mt-3 d-flex align-items-center">
              <div>
                <h2>Contact Us</h2>
                <p className='pt-2'>We'd love to hear from you! Whether you have questions about our programs, admissions, or any other inquiries, please don't hesitate to reach out. Fill out the form below, and our team will get back to you promptly. Your child's bright future starts here!</p>
                <h6 className='mt-4'>Address</h6>
                <p className='pt-2'>
                  Sri SPR Global School, Chittaramma Devi Nagar, <br /> Gajularamaram,  Hyderabad, Telandana - 500055 <br />
                  <span className="phone_num">Phone: <a href="tel:+917997995252">(+91) 799 799 5252</a></span>
                </p>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12 right_col mt-5">
              <div className="card border-0">
                <div className="container">
                  <div className="row py-3">
                    <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12 pt-5 pb-5">
                      <h5>Get In Touch!</h5>
                      <form name='contactForm' id='contactForm' onSubmit={contactForm}>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-3 ">
                            <div className="input-group">
                              <input autoComplete="off" type="text" className="first_name" id="first_name" name="first_name"
                                placeholder="First Name" value={formData.first_name} onChange={handleChange} />
                              {errors.first_name && <p className="text-danger">{errors.first_name}</p>}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-3 ">
                            <div className="input-group">
                              <input autoComplete="off" type="text" className="second_name" id="second_name"
                                name="second_name" placeholder="Second Name" value={formData.second_name} onChange={handleChange} />
                              {errors.second_name && <p className="text-danger">{errors.second_name}</p>}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                            <div className="input-group">
                              <input autoComplete="off" type="number" className="mobile_number" id="mobile_number"
                                name="mobile_number" placeholder="Phone Number" value={formData.mobile_number} onChange={handleChange} />
                              {errors.mobile_number && <p className="text-danger">{errors.mobile_number}</p>}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                            <div className="input-group">
                              <input autoComplete="off" type="email" className="email" id="email" name="email"
                                placeholder="Email" value={formData.email} onChange={handleChange} />
                              {errors.email && <p className="text-danger">{errors.email}</p>}
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                            <textarea rows="5" name="message" placeholder="Message" autoComplete="off"
                              className="message mt-3" id="message" value={formData.message} onChange={handleChange}></textarea>
                            {errors.message && <p className="text-danger">{errors.message}</p>}
                          </div>
                          <div className="mt-5 text-center">
                            <button className="allBtns">Submit <i className="fa-solid fa-arrow-right"></i></button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
