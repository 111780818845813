import React, { useEffect } from 'react'
import abRou from '../assets/images/acdemics/acdamics.jpg';
import academicsAccImg from '../assets/images/acdemics/academicsAccImg.jpg';
import { Accordion } from 'react-bootstrap';

export default function Academics() {
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <>
      <section className="cincyrinfo_sec allFirstSections">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12 text-center">
              <img src={abRou} className="hellolll" width="70%" alt="abRou" />
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 d-flex align-items-center">
              <div>
                <p className='subHeading'>Sri SPR Global School</p>
                <h2 className='pt-2'>Curriculum</h2>
                <p className='mt-2'>Sri SPR Global School was founded in 2024 with the aim of providing quality education to students from all backgrounds. We offer a holistic curriculum that emphasizes the all-round development of the child. The academic program at Sri SPR Global School is designed to challenge the students and help them grow academically, emotionally, and socially. Our curriculum is tailored to the needs of our students and is regularly updated to keep pace with changing times. We believe in providing a well-rounded education to our students, ensuring they are prepared for future success.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='academicAccordionSec'>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12">
              <Accordion defaultActiveKey="3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Pre School</Accordion.Header>
                  <Accordion.Body className="py-4">
                    <p>Our preschool curriculum is designed to provide a stimulating and nurturing environment for young learners. Through play-based activities and hands-on experiences, children develop essential skills such as language development, social interaction, and motor skills. We focus on building a strong foundation in literacy and numeracy, ensuring that each child progresses at their own pace.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>1st to 5th Grade</Accordion.Header>
                  <Accordion.Body className="py-4">
                    <p>In the early elementary years, our focus is on building a strong academic foundation while nurturing a love for learning. Students develop foundational skills in reading, writing, and mathematics, laying the groundwork for more complex learning in later grades. Our curriculum integrates hands-on activities and real-world applications to make learning engaging and meaningful.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>6th to 7th Grade</Accordion.Header>
                  <Accordion.Body className="py-4">
                    <p>As students transition to middle school, our curriculum becomes more comprehensive, covering a wide range of subjects including mathematics, science, social studies, and language arts. We emphasize critical thinking, problem-solving, and communication skills, preparing students for the challenges of high school and beyond.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" >
                  <Accordion.Header>High School</Accordion.Header>
                  <Accordion.Body className="py-4">
                    <p className='pt-2'>In the final years of high school, our focus shifts to preparing students for the board exams. Our curriculum is aligned with state board standards and includes rigorous coursework in all subjects. We also provide guidance and support for college and career planning, helping students make informed decisions about their future.</p>
                    <p className='pt-2'>We aim to shape our students into well-rounded individuals who embody knowledge, ethics, morals, and discipline, ensuring they leave a lasting impact on the world. In today's technology-driven and fast-paced society, where values are often overlooked, we at Shri SPR School are dedicated to the holistic development of each child. Academic excellence and discipline are our top priorities, and we strive to ensure that every student receives personalized attention to excel academically and develop a well-rounded personality.</p>
                    <p className='pt-2'>Our high school curriculum is designed to prepare students thoroughly for Board Exams, with a focus on building confidence and competence for the state board exams. This preparation includes:</p>
                    <ul className='pt-2'>
                      <li>Continuous evaluation aligned with SSC Board standards.</li>
                      <li>Emphasis on oral (speaking) and aural (listening) skills as part of the SSC English Project.</li>
                      <li>Enhanced learning through seminars, audio-visual aids, PowerPoint presentations, and role play.</li>
                    </ul>
                    <p className='pt-2'>Join us at Sri SPR Global School, where we nurture future leaders with a balanced approach to academics and personal growth.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12">
              <img src={academicsAccImg} width="100%" height="" alt="academicsAccImg" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
