import React from 'react'
import bussFlex from '../../assets/images/aboutus/bussFlex.png';
import communicatoin from '../../assets/images/aboutus/communicatoin.png';
import exec from '../../assets/images/aboutus/exec.png';
import Pricing from '../../assets/images/aboutus/Pricing.png';
import standardApp from '../../assets/images/aboutus/standardApp.png';
import techExp from '../../assets/images/aboutus/techExp.png';

export default function GearingUp() {
    return (
        <>
            <section className="gearup_business p-0">
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-4 col-md-6 col-sm-12 left_col d-flex align-items-center">
                            <div className="row pt-5 pb-5 mt-5 mb-5">
                                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
                                    <h5>At Sri SPR Global School, we pride ourselves on providing a comprehensive education that nurtures each student's intellectual, social, and emotional growth.  </h5>
                                    <p className="pt-3">Our innovative curriculum balances academic rigor with creative expression and physical activity, supported by state-of-the-art facilities and dedicated faculty. </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-8 col-md-6 col-sm-12 right_col">
                            <div className="row pt-5 pb-5 mt-5 mb-5">
                                <div className="col-lg-12 col-md-12 col-sm-12 ">
                                    <div className="row">
                                        <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
                                            {/* <img src={standardApp} width="50px" alt="standardApp" /> */}
                                            <h5 className=""> <strong>Our Philosophy</strong> </h5>
                                            <p className="mt-3">Our educational philosophy centers on the belief that learning should be a joyful and engaging experience. We emphasize a balanced approach to education that integrates academic rigor with creative expression, physical activity, and social-emotional learning. By nurturing the intellectual, emotional, and social aspects of each student, we prepare them to be well-rounded individuals ready to face the challenges of the future.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
                                    <hr className='allHrLines my-3'/>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 ">
                                    <div className="row">
                                        <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
                                            {/* <img src={communicatoin} width="50px" alt="communicatoin" /> */}
                                            <h5 className=""> <strong>Curriculum</strong> </h5>
                                            <p className="mt-3"> Our comprehensive curriculum is designed to cater to the diverse learning needs of our students. In the early years, our Preschool program focuses on play-based learning, helping young children develop foundational skills in a fun and interactive way. As students progress to elementary and middle school, we introduce a more structured academic framework, emphasizing core subjects such as Mathematics, Science, Language Arts, and Social Studies. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
                                    <hr className='allHrLines my-3'/>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                    <div className="row">
                                        <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
                                            {/* <img src={techExp} width="50px" alt="techExp" /> */}
                                            <h5 className=""> <strong>Extracurricular Activities</strong> </h5>
                                            <p className="mt-3">At [Sri SPR Global School], we recognize the importance of extracurricular activities in the overall development of our students. We offer a wide range of programs, including sports, arts, music, dance, and various clubs and societies. These activities provide students with opportunities to discover their passions, develop leadership skills, and build lasting friendships.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
                                    <hr className='allHrLines my-3'/>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                    <div className="row">
                                        <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
                                            {/* <img src={bussFlex} width="50px" alt="bussFlex" /> */}
                                            <h5 className=""> <strong>Facilities</strong> </h5>
                                            <p className="mt-3">Our state-of-the-art campus is equipped with modern facilities to support both academic and extracurricular pursuits. We have well-equipped classrooms, science and computer labs, a library stocked with a vast collection of books and resources, art and music studios, and sports facilities including a playground, basketball court, and swimming pool. Our campus also features safe and comfortable spaces for students to relax and socialize.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
                                    <hr className='allHrLines my-3'/>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                    <div className="row">
                                        <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
                                            {/* <img src={Pricing} width="50px" alt="Pricing" /> */}
                                            <h5 className=""> <strong>Faculty</strong> </h5>
                                            <p className="mt-3">Our dedicated team of educators is our greatest asset. Our teachers are highly qualified, experienced, and passionate about teaching. They are committed to creating a supportive and dynamic learning environment where students feel valued and encouraged to reach their full potential. Continuous professional development ensures that our teachers stay updated with the latest educational practices and technologies.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
                                    <hr className='allHrLines my-3'/>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                    <div className="row">
                                        <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
                                            {/* <img src={exec} width="50px" alt="exec" /> */}
                                            <h5 className=""> <strong>Community and Values</strong> </h5>
                                            <p className="mt-3">We are proud of our diverse and inclusive school community. At Sri SPR Global School, we celebrate the unique backgrounds and cultures of our students and families. Our values of respect, integrity, empathy, and responsibility are woven into the fabric of our school culture, guiding our interactions and shaping our students into compassionate and responsible global citizens.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
                                    <hr className='allHrLines my-3'/>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                    <div className="row">
                                        <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
                                            {/* <img src={exec} width="50px" alt="exec" /> */}
                                            <h5 className=""> <strong>Parent Involvement</strong> </h5>
                                            <p className="mt-3">We believe that parents play a crucial role in the educational journey of their children. We foster strong partnerships with parents through regular communication, parent-teacher meetings, and involvement in school activities. Together, we create a supportive network that enhances the learning experience for our students.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
