import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Header from '../components/Header';
import HomePage from '../pages/HomePage';
import Contactus from './Contactus';
import Footer from '../components/Footer';
import Gallery from './Gallery';
import Aboutus from './Aboutus';
import Admissions from './Admissions';
import LifeSprSchool from './LifeSprSchool';
import ComingSoon from './ComingSoon';
import Academics from '../pages/Academics';

export default function RootRouter() {
  return (
    <div>
      <BrowserRouter>
      <div>
        <Header />
        <Routes>
          <Route path='/' element={<HomePage />}/>
          <Route path='/gallery' element={ <Gallery /> }/> 
          <Route path='/about-us' element={ <Aboutus /> }/> 
          <Route path='/contact-us' element={ <Contactus/> }/>
          <Route path='/admissions' element={ <Admissions /> }/>
          <Route path='/life-spr-school' element={ <LifeSprSchool /> }/>
          
          {/* <Route path='/' element={ <ComingSoon /> }/> */}
          <Route path='/academics' element={ <Academics />  } />

        </Routes>
        <Footer />
      </div>
      </BrowserRouter>
    </div>
  )
}







// Remove when webise go live 



// import React from 'react';
// import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
// import Header from '../components/Header';
// import HomePage from '../pages/HomePage';
// import Contactus from './Contactus';
// import Footer from '../components/Footer';
// import Gallery from './Gallery';
// import Aboutus from './Aboutus';
// import Admissions from './Admissions';
// import LifeSprSchool from './LifeSprSchool';
// import ComingSoon from './ComingSoon';
// import Academics from './Academics';
// // import Academics from '../pages/Academics';

// function AppRoutes() {
//   const location = useLocation();
//   const hideHeaderFooter = location.pathname === '/';

//   return (
//     <div>
//       {!hideHeaderFooter && <Header />}
//       <Routes>
//         <Route path='/welcome' element={<HomePage />} />
//         <Route path='/gallery' element={<Gallery />} />
//         <Route path='/about-us' element={<Aboutus />} />
//         <Route path='/contact-us' element={<Contactus />} />
//         <Route path='/admissions' element={<Admissions />} />
//         <Route path='/life-spr-school' element={<LifeSprSchool />} />
//         <Route path='/' element={<ComingSoon />} />
//         <Route path='/academics' element={ <Academics />  } />

//       </Routes>
//       {!hideHeaderFooter && <Footer />}
//     </div>
//   );
// }

// export default function RootRouter() {
//   return (
//     <BrowserRouter>
//       <AppRoutes />
//     </BrowserRouter>
//   );
// }
