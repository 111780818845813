import './App.css';
import RootRouter from './pages/RootRouter';
import whatsapp from './assets/images/whatsapp.png';
function App() {
  return (
    <div className="App">
      <RootRouter />
      <div className="whatsapp">
        <a href="https://wa.me/+917997995252" target="_blank"
          className="d-flex align-items-center justify-content-center">
          <img src={whatsapp} alt="" />
        </a>
      </div>

    </div>
  );
}

export default App;
