import React from 'react'
import abRou from '../../assets/images/aboutus/abRou.jpg';
export default function About() {
    return (
        <>
            <section className="cincyrinfo_sec allFirstSections">
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-sm-12 text-center">
                            <img src={abRou} className="hellolll" width="70%" alt="abRou" />
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 d-flex align-items-center">
                            <div>
                            <p className='subHeading'>Sri SPR Global School</p>
                            <h2 className='pt-2'>About Us</h2>
                            <p>Welcome to Sri SPR Global School, a nurturing educational institution dedicated to fostering the holistic development of children from Preschool to 10th Grade. At Sri SPR Global School, we believe that every child is unique and capable of achieving great things with the right guidance and support. Our mission is to provide a safe, inclusive, and stimulating environment where students can explore their interests, develop their talents, and achieve academic excellence.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
