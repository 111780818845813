import React, { useEffect } from 'react'

import spr1 from '../assets/images/OurGallery/spr1.jpg';
import spr2 from '../assets/images/OurGallery/spr2.jpg';
import spr3 from '../assets/images/OurGallery/spr3.jpg';
import spr4 from '../assets/images/OurGallery/spr4.jpg';
import spr5 from '../assets/images/OurGallery/spr5.jpg';
import spr6 from '../assets/images/OurGallery/spr6.jpg';
import spr7 from '../assets/images/OurGallery/spr7.jpg';
import spr8 from '../assets/images/OurGallery/spr8.jpg';
import spr9 from '../assets/images/OurGallery/spr9.jpg';
import spr10 from '../assets/images/OurGallery/spr10.jpg';
import spr11 from '../assets/images/OurGallery/spr11.jpg';
import spr12 from '../assets/images/OurGallery/spr12.jpg';
import spr13 from '../assets/images/OurGallery/spr13.jpg';
import spr14 from '../assets/images/OurGallery/spr14.jpg';
import spr15 from '../assets/images/OurGallery/spr15.jpg';
import spr16 from '../assets/images/OurGallery/spr16.jpg';
import spr17 from '../assets/images/OurGallery/spr17.jpg';
import spr18 from '../assets/images/OurGallery/spr18.jpg';
import spr19 from '../assets/images/OurGallery/spr19.jpg';
import spr20 from '../assets/images/OurGallery/spr20.jpg';
import spr21 from '../assets/images/OurGallery/spr21.jpg';
import spr22 from '../assets/images/OurGallery/spr22.jpg';
import spr23 from '../assets/images/OurGallery/spr23.jpg';
import spr24 from '../assets/images/OurGallery/spr24.jpg';
import spr25 from '../assets/images/OurGallery/spr25.jpg';
import spr26 from '../assets/images/OurGallery/spr26.jpg';
import spr27 from '../assets/images/OurGallery/spr27.jpg';
import spr28 from '../assets/images/OurGallery/spr28.jpg';
import spr29 from '../assets/images/OurGallery/spr29.jpg';


export default function Gallery() {
    useEffect(() => {
        window.scrollTo(0, 0);
      })
     
    return (
        <section className='allFirstSections gallerySec'>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center mt-5">
                        <h2>Our School Gallery</h2>
                        <p>Explore our vibrant school life through our gallery. From classroom activities and sports events to cultural celebrations and field trips, see how our students learn, grow, and thrive every day. Scroll through the moments that make our school a wonderful place to be!</p>
                    </div>
                    <div className="col-12 mt-5">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr14} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr11} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr12} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr10} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr13} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr28} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr5} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr26} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr7} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr2} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr27} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            


                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr1} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                           
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr3} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr4} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                           
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr6} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                           
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr8} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr9} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            
                            
                           
                            
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr15} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr16} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr17} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr18} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr19} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr20} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr21} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr22} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr23} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr24} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr25} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                            
                           
                            
                            <div className="col-lg-3 col-md-4 co-sm-6 col-12 pt-3">
                                <img src={spr29} className='allGalleryImg' width="100%" height="220px" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
