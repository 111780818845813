import React, { useEffect } from 'react'

import Academics from '../components/HomeComponents/Academics'
import AboutUs from '../components/HomeComponents/AboutUs'
import VisionMission from '../components/HomeComponents/VisionMission'
import Events from '../components/HomeComponents/Events'
import FirstSection from '../components/HomeComponents/FirstSection'
import Gallery from '../components/HomeComponents/Gallery'
import ContactUsBanner from '../components/ContactUsBanner'
// import Footer from '../components/Footer'


export default function HomePage() {
   
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  
  return (
    <>
      <section className='allFirstSections HomeFirstSec'>
        <div className="container">
            <div className="row">
              <div className="col-12">
              <FirstSection />
              </div>
            </div>
        </div>
      </section>
      <Events />
      <AboutUs />
      <Academics />
      <VisionMission />
      <Gallery />
      <ContactUsBanner />
    </>
  )
}
