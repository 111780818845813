import React, { useEffect, useState } from 'react'
import { getApi } from "../utilits/helpers/ApiServices";
import { Link } from 'react-router-dom'
// import sprV1 from '../assets/images/EventsV/sprV1.mp4'
// import sprV2 from '../assets/images/EventsV/sprV2.mp4'

const convertDateFormat = (dateStr) => {
    const [day, month, year] = dateStr.split('-');
    const date = new Date(`${year}-${month}-${day}`);
    return date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
};

const getYouTubeEmbedUrl = (url) => {
    const videoId = url.split('v=')[1];
    const ampersandPosition = videoId.indexOf('&');
    return ampersandPosition !== -1
        ? `https://www.youtube.com/embed/${videoId.substring(0, ampersandPosition)}`
        : `https://www.youtube.com/embed/${videoId}`;
};

export default function LifeSprSchool() {
    useEffect(() => {
        // window.scrollTo(0, 0);
        fetchEventsData();
    })

    const [videos, setVideos] = useState([]);
    const [images, setImages] = useState([]);
    const [url, setUrl] = useState();
    const [activeTab, setActiveTab] = useState('London');
    const openCity = (cityName) => {
        setActiveTab(cityName);
    };

    const baseUrl = "https://api.srisprglobalschool.com/"
    

    const fetchEventsData = async () => {
        try {
            const res = await getApi("/events/get");
            const videoTags = res.response.data.flatMap(event => event.video_tags.map(getYouTubeEmbedUrl));
            setVideos(videoTags);
            // const imagePaths = res.response.data.flatMap(event => event.image_paths.map(path => path.replace(/\\/g, '/')));
            const imagePaths = res.response.data.flatMap(event => 
                event.image_paths.map(path => ({
                    url: path.replace(/\\/g, '/'),
                    title: event.title,
                    description: event.description
                }))
            );
            setImages(imagePaths);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <section className="allFirstSections EventsSec commmonSecSeperate">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-12 ">
                            <div className="row">
                                <p className="subHeading">Showcasing Our School's Vibrant Life</p>
                                <h2 className='pt-2'>Life@SriSPRSchool </h2>
                                <p>
                                    We will showcase below the exciting events and activities happening at our school. From academic competitions and sports tournaments to cultural festivals and community service projects, you'll see the dynamic and enriching experiences that make our school a place of growth and discovery.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row tab mt-5">
                        <div className="col-lg-6">
                            <button className={`tablinks ${activeTab === 'London' ? 'active' : ''}`} onClick={() => openCity('London')}>Event Videos</button>
                            <button className={`tablinks ms-3 ${activeTab === 'Paris' ? 'active' : ''}`} onClick={() => openCity('Paris')}>Event Images</button>
                        </div>
                        <div className="col-lg-6">

                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12">
                            <div id="London" className="tabcontent" style={{ display: activeTab === 'London' ? 'block' : 'none' }}>
                                <div className="row">
                                    {videos?
                                        videos.map((video, i) => (
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt-5" key={i}>
                                                <iframe src={video} id="videoFrame" className="w-100"
                                                    height="400px" frameBorder="0" allowFullScreen></iframe>
                                            </div>
                                        ))
                                    :
                                    <h6>No videos available</h6>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div id="Paris" className="tabcontent" style={{ display: activeTab === 'Paris' ? 'block' : 'none' }}>
                                <div className="row">
                                    {images.map((img, i) => (
                                        <div className="col-lg-4 pt-4" key={i}>
                                            <div className="card img-fluid">
                                                <img
                                                    className="card-img-top eventImages"
                                                    src={`${baseUrl}${img.url}`}
                                                    alt="Card image"
                                                />
                                                <div className="card-img-overlay">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {/* <h6>{convertDateFormat(img.publishDate)}</h6> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-img-overlay  d-flex align-items-end">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h6>{img.title}</h6>
                                                            <p>{img.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}