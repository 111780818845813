import React from 'react'

export default function Value() {
    return (
        <>
            <section className="values_sec commmonSecSeperate">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 d-flex align-items-center">
                            <div>
                                 
                                <div className="row ">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-3 pt-2">
                                        <h5>Vision</h5>
                                        <p className="pt-3">Empowering Students to be tomorrow's leaders with a foundation of moral integrity and academic excellence. Nurturing students into individuals who will positively impact society globally</p>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-3 pt-2" >
                                        <h5>Mission</h5>
                                        <p className="pt-3">Our mission is to provide a Holistic education that instills moral values develops critical thinking, nurtures creativity and equips students with the skills and mindset to excel in a rapidly changing world.</p>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-3 pt-2" >
                                        <h5>Motto</h5>
                                        <p className="pt-3">Shiksha, Sanskriti, Samriddhi: 3S - Nurturing young Minds, Inspiring Innovation, Empowering Global Citizens.</p>
                                    </div>
                                     
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-none d-lg-block">
                            <div className="col-12 text-center value_col pt-5 pb-5">
                                <h2 className="mt-5">V</h2>
                                <h2 className="mt-5">A</h2>
                                <h2 className="mt-5">L</h2>
                                <h2 className="mt-5">U</h2>
                                <h2 className="mt-5">E</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
