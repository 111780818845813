import React from 'react'
import { Link } from 'react-router-dom'
import contactBanner from '../assets/images/contactBanner.png'
export default function ContactUsBanner() {
    return (
        <>
            <section className='contactBannerSec  d-none'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-7 col-sm-6 col-12 d-flex align-items-center mt-3" data-aos="fade-right" data-aos-duration="3000">
                            <div>
                                <h2 className="text-white">Let's Grow TOGETHER</h2>
                                <p className='text-white mt-3'>Join a growing team where you can have visible impact on client projects and on our software and service offerings.</p>
                                <div className="col-12 mt-3">
                                <Link to="/contact-us"><button className="getin_touch_btn">Get In Touch</button></Link>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4 col-md-5 col-sm-6 col-12 mt-3 ContactBannerImgCol" data-aos="fade-left" data-aos-duration="3000">
                            <img src={contactBanner} alt="car_ban_img" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
