import React, { useEffect, useState } from 'react'
import { postApi } from '../utilits/helpers/ApiServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactUsBanner from "../components/ContactUsBanner";
import academics from "../assets/images/home/academics.png";
import admissionSecImg from "../assets/images/admissions/admissionSecImg.jpg";
import academicsAccImg from '../assets/images/acdemics/academicsAcc.jpg';

export default function Admissions() {
    const [formData, setFormData] = useState({
        parent_name: '',
        student_name: '',
        gender: '',
        mobile_number: '',
        class_seeking_admission_to: '',
        presently_studying_school: '',
        message: '',

    })
    const [errors, setErrors] = useState({})

    // useEffect(() => {
    //     // window.scrollTo(0, 0);
    // })


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: ""
        })
    }

    const postData = async (data) => {
        try {
          const response = await postApi('/application/create', data);
          setFormData({
            parent_name: '',
            student_name: '',
            gender: '',
            mobile_number: '',
            class_seeking_admission_to: '',
            presently_studying_school: '',
            message: '',
          })
          let successMessage = "Thanks for your interest. We will get back to you shortly.";
          toast.success(successMessage);
          console.log(response);
        } catch (error) {
          console.log('Error caught:', error);
          let errorMessage = 'An error occurred';
          if (error.response) {
            // Extracting the specific message from the server response
            if (error.response.data && error.response.data.message) {
              errorMessage = error.response.data.message;
            } else if (typeof error.response.data === 'string') {
              errorMessage = error.response.data;
            } else {
              errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
            }
          } else if (error.request) {
            errorMessage = 'No response received from server';
          } else {
            errorMessage = error.message;
          }
    
          toast.error(errorMessage);
    
        }
      }
      
    const handleSubmit = (e) => {
        e.preventDefault();
        // window.scrollTo(0, 0);
        const validationErrors = validate(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            console.log('ncncncnnc')
            postData(formData);
        }
    }


    const validate = (formData) => {
        const errors = {};
        if (!formData.parent_name) {
            errors.parent_name = "Parent name is required";
        }
        if (!formData.student_name) {
            errors.student_name = "Student name is required";
        }
        if (!formData.gender) {
            errors.gender = "Gender is required";
        }
        if (!formData.mobile_number) {
            errors.mobile_number = "Mobile number is required";
        }
        if (!formData.class_seeking_admission_to) {
            errors.class_seeking_admission_to = "Class seeking admission to is required";
        }
        if (!formData.presently_studying_school) {
            errors.presently_studying_school = "Presently studying school is required";
        }
        if (!formData.message) {
            errors.message = "Message is required";
        }
        return errors;
    };


    return (
        <>
            <section className="allFirstSections admissionSec">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mt-lg-5 mt-2">
                            <p className="subHeading">Welcome to Sri SPR Global School's Admissions Page!
                            </p>
                            <h2 className="mt-2">Admissions at Sri SPR Global School
                            </h2>
                            <p>
                                At Sri SPR Global School, we are dedicated to nurturing young minds and fostering an environment that promotes academic excellence, personal growth, and holistic development. Our admissions process is designed to be transparent, efficient, and welcoming for all prospective students and their families.
                            </p>
                        </div>
                        <div className="col-lg-6 mt-5 pt-lg-5 pt-2 d-flex align-items-center">
                            <div>
                                <h2>Admission Process
                                </h2>
                                <p className="mt-3">
                                    To begin the admission process at Sri SPR Global School, complete the online inquiry form and download or collect the application form. Schedule a campus tour to explore our facilities and meet our faculty. Submit the completed application with the required documents, including the birth certificate, previous academic records, and a recent photograph. Certain grades may require an entrance assessment to gauge academic readiness. Parents and students will then attend an interview with our admissions team to discuss interests and educational goals. Successful applicants will receive an admission offer and, upon acceptance, complete the enrollment process by paying the fees.
                                </p>
                                <p className="mt-3">
                                    We look forward to welcoming you to the Sri SPR Global School family and partnering with you in your child’s educational journey. Together, let's pave the way for a bright and successful future.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-5 pt-lg-5 pt-2">
                            <img src={admissionSecImg} width="100%" height="500px" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                            <img src={academics} alt="" />
                            <h5>Holistic Curriculum</h5>
                            <p>Our comprehensive curriculum is tailored to meet the diverse needs of our students, ensuring they excel academically while also developing essential life skills.
                            </p>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                            <img src={academics} alt="" />
                            <h5>Qualified Faculty</h5>
                            <p>Our experienced and dedicated teachers are committed to providing personalized attention to each student, encouraging them to reach their full potential.
                            </p>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                            <img src={academics} alt="" />
                            <h5>Extracurricular Activities</h5>
                            <p>We offer a wide range of extracurricular activities, including sports, arts, and clubs, to foster creativity and teamwork.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <ToastContainer />
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-12 offset-lg-0 col-md-12 offset-md-0 col-sm-12 col-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 pt-3 p-0">
                                    <img src={academicsAccImg} width="100%" height="550px" alt="academicsAccImg" />
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 pt-3 bg-white d-flex align-items-end">
                                    <form name='admissionForm' id='admissionForm' onSubmit={handleSubmit} >
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <h2>Admission Enquiry For Academic Year 2023-24</h2>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="parent_name" className="form-label">Parent Name<span className="text-danger">*</span></label>
                                                    <input autoComplete="off" type="text" name="parent_name" id="parent_name" placeholder="Parent Name" value={formData.parent_name} onChange={handleChange} />
                                                    {errors.parent_name && <p className="text-danger">{errors.parent_name}</p>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="student_name" className="form-label">Student Name<span className="text-danger">*</span></label>
                                                    <input autoComplete="off" type="text" name="student_name" id="student_name" placeholder="Student Name" value={formData.student_name} onChange={handleChange} />
                                                    {errors.student_name && <p className="text-danger">{errors.student_name}</p>}

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="gender" className="form-label">Gender <span className="text-danger">*</span></label>
                                                    <select name="gender" id="gender" value={formData.gender} onChange={handleChange}>
                                                        <option selected disabled>Select Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                    {errors.gender && <p className="text-danger">{errors.gender}</p>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="mobile_number" className="form-label">Mobile Number <span className="text-danger">*</span></label>
                                                    <input autoComplete="off" type="number" name="mobile_number" id="mobile_number" placeholder="Mobile Number" value={formData.mobile_number} onChange={handleChange} />
                                                    {errors.mobile_number && <p className="text-danger">{errors.mobile_number}</p>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="class_seeking_admission_to" className="form-label">Class Seeking Admission To <span className="text-danger">*</span></label>
                                                    <select name="class_seeking_admission_to" id="class_seeking_admission_to" value={formData.class_seeking_admission_to} onChange={handleChange}>
                                                        <option value="" selected disabled>Class Seeking Admission To</option>
                                                        <option value="PreSchool">Pre School</option>
                                                        <option value="Nursery">Nursery</option>
                                                        <option value="LKG">LKG</option>
                                                        <option value="UKG">UKG</option>
                                                        <option value="1st">1st Class</option>
                                                        <option value="2nd">2nd Class</option>
                                                        <option value="3rd">3rd Class</option>
                                                        <option value="4th">4th Class</option>
                                                        <option value="5th">5th Class</option>
                                                        <option value="6th">6th Class</option>
                                                        <option value="7th">7th Class</option>
                                                        <option value="8th">8th Class</option>
                                                        <option value="9th">9th Class</option>
                                                        <option value="10th">10th Class</option>
                                                    </select>
                                                    {errors.class_seeking_admission_to && <p className="text-danger">{errors.class_seeking_admission_to}</p>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="presently_studying_school" className="form-label">Presently Studying School <span className="text-danger">*</span></label>
                                                    <input autoComplete="off" type="text" name="presently_studying_school" id="presently_studying_school" placeholder="Presently Studying School Name" value={formData.presently_studying_school} onChange={handleChange} />
                                                    {errors.presently_studying_school && <p className="text-danger">{errors.presently_studying_school}</p>}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="message" className="form-label">Message <span className="text-danger">*</span></label>
                                                    <textarea name="message" id="message" value={formData.message} onChange={handleChange}></textarea>
                                                    {errors.message && <p className="text-danger">{errors.message}</p>}
                                                </div>
                                            </div>
                                            <div className="col-12 pt-5">
                                                <button type='submit' className="allBtns w-100">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContactUsBanner />
        </>
    );
}
