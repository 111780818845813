import React, { useRef } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import flyLife from '../../assets/images/home/flyLife.jpg'
import flyLifeTwo from '../../assets/images/home/flyLife2.jpg'
import flyLifeThree from '../../assets/images/home/flyLife3.jpg'
import Academics from '../../assets/images/home/academicsImg.jpg'
import admission from '../../assets/images/home/admissionImg.jpg'
import left from '../../assets/images/home/left.png'
import right from '../../assets/images/home/right.png'
import { Link } from 'react-router-dom';

// export default function FirstSection() {
      
//     var settings = {
//         infinite: true,
//         autoplay: true,
//         autoplaySpeed: 5000,
//         pauseOnHover: true,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//     };
//         // nextArrow: document.getElementById('NextBtn'),
//         // prevArrow: document.getElementById('prevBtn'),

//     return (
//         <>
//             <Slider {...settings}>
//                 <div className="col-12">
//                     <div className="row">
//                         <div className="col-lg-6 d-flex align-items-center">
//                             <div>
//                                 <h1>School</h1>
//                                 <h2>Generation At Home</h2>
//                                 <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates impedit alias ab ipsum blanditiis dolores exercitationem at inventore.</p>
//                                 <button className='allBtns mt-5'>More Details <i className="fa-solid fa-arrow-right" /></button>
//                             </div>
//                         </div>
//                         <div className="col-lg-6 mt-5 pt-3">
//                             <div className="RightCol d-flex align-items-center">
//                                 <div className="container">
//                                     <div className="row">
//                                         <div className="col-4 topImageDiv">
//                                             <img src={admission} alt="admission" className='TopImg' />
//                                         </div>
//                                         <div className="col-12 d-flex justify-content-end">
//                                             <img src={Academics} className='bottmImg' alt="Card image" />
//                                         </div>
//                                         <div className="col-12 d-flex justify-content-center">
//                                             <img src={admission} alt="admission" width="90%" height="230px" className='TopImg fullImg d-none' />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-12">
//                     <div className="row">
//                         <div className="col-lg-6 d-flex align-items-center">
//                             <div>
//                                 <h1>School1 </h1>
//                                 <h2>Generation At Home</h2>
//                                 <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates impedit alias ab ipsum blanditiis dolores exercitationem at inventore.</p>
//                                 <button className='allBtns mt-5'>More Details <i className="fa-solid fa-arrow-right" /></button>
//                             </div>
//                         </div>
//                         <div className="col-lg-6 mt-5 pt-3">
//                             <div className="RightCol d-flex align-items-center">
//                                 <div className="container">
//                                     <div className="row">
//                                         <div className="col-4 topImageDiv">
//                                             <img src={admission} alt="admission" className='TopImg' />
//                                         </div>
//                                         <div className="col-12 d-flex justify-content-end">
//                                             <img src={Academics} className='bottmImg' alt="Card image" />
//                                         </div>
//                                         <div className="col-12 d-flex justify-content-center">
//                                             <img src={admission} alt="admission" width="90%" height="230px" className='TopImg fullImg d-none' />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-12">
//                     <div className="row">
//                         <div className="col-lg-6 d-flex align-items-center">
//                             <div>
//                                 <h1>School 2</h1>
//                                 <h2>Generation At Home</h2>
//                                 <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates impedit alias ab ipsum blanditiis dolores exercitationem at inventore.</p>
//                                 <button className='allBtns mt-5'>More Details <i className="fa-solid fa-arrow-right" /></button>
//                             </div>
//                         </div>
//                         <div className="col-lg-6 mt-5 pt-3">
//                             <div className="RightCol d-flex align-items-center">
//                                 <div className="container">
//                                     <div className="row">
//                                         <div className="col-4 topImageDiv">
//                                             <img src={admission} alt="admission" className='TopImg' />
//                                         </div>
//                                         <div className="col-12 d-flex justify-content-end">
//                                             <img src={Academics} className='bottmImg' alt="Card image" />
//                                         </div>
//                                         <div className="col-12 d-flex justify-content-center">
//                                             <img src={admission} alt="admission" width="90%" height="230px" className='TopImg fullImg d-none' />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </Slider>
//             <div className="row">
//                 <div className="col-12 nextPrevCol">
//                     <span>
//                         <button id='prevBtn'><img src={left} width="16px" alt="" /> Previous</button>
//                         <span className='horizontalLine'></span>
//                         <button id='NextBtn'>Next <img src={right} width="16px" alt="" /></button>
//                     </span>
//                 </div>
//             </div>
//         </>
//     )
// }


const FirstSection = () => {
    const sliderRef = useRef(null);

    const settings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div>
            <Slider ref={sliderRef} {...settings}>
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div>
                                <p className='subHeading'>Preparing for Tomorrow</p>
                                <h1 className='pt-2'>Creating Bright Futures</h1>
                                <p className='mt-3'>Our school is committed to preparing students for a successful and fulfilling future. We equip them with the knowledge, skills, and values they need to thrive in a rapidly changing world. </p>
                                <Link to={"/admissions"}><button className='allBtns mt-5'>ADMISSIONS <i className="fa-solid fa-arrow-right" /></button></Link>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-5 pt-3">
                            <div className="RightCol ">
                                 <div className="col-12 d-flex align-items-center justify-content-center">
                                    <img src={flyLifeTwo} alt="admission" height="400px" width="80%" className='homeImgs' />
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div>
                                <p className='subHeading'>Igniting Potential, Inspiring Excellence</p>
                                <h1 className='pt-2'>Empowering Young Minds</h1>
                                <p className='mt-3'>At our school, we believe every child has unique talents and abilities waiting to be discovered. We provide a nurturing and stimulating environment where students can explore their interests, develop their skills, and achieve their full potential. </p>
                                <Link to={"/admissions"}><button className='allBtns mt-5'>ADMISSIONS <i className="fa-solid fa-arrow-right" /></button></Link>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-5 pt-3">
                            <div className="RightCol ">
                                 <div className="col-12 d-flex align-items-center justify-content-center">
                                    <img src={flyLifeThree} alt="admission" height="400px" width="80%" className='homeImgs' />
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div>
                                <p className='subHeading'>Unlocking Unlimited Potential</p>
                                <h1 className='pt-2'>Let's Your Child Fly</h1>
                                 
                                <p className='mt-3'>At our school, we believe every child has the ability to soar. Our dedicated teachers and innovative programs provide the support and encouragement needed for students to reach new heights and achieve their dreams. Join us in creating a bright future for your child. </p>
                                <Link to={"/admissions"}><button className='allBtns mt-5'>ADMISSIONS <i className="fa-solid fa-arrow-right" /></button></Link>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-5 pt-3">
                            <div className="RightCol ">
                                 <div className="col-12 d-flex align-items-center justify-content-center">
                                    <img src={flyLife} alt="admission" height="400px" width="80%" className='homeImgs' />
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
            <div className="row mt-5">
                <div className="col-12 nextPrevCol">
                    <span>
                        <button id='prevBtn' onClick={() => sliderRef.current.slickPrev()}><img src={left} width="16px" alt="" /> Previous</button>
                        <span className='horizontalLine'></span>
                        <button id='NextBtn' onClick={() => sliderRef.current.slickNext()}>Next <img src={right} width="16px" alt="" /></button>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default FirstSection;
