import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainLogo from "../assets/images/sprLogo.png";
import mainLogoStrip from "../assets/images/mainLogoStrip.jpg";
import facebook from "../assets/images/face.png";
import instagram from "../assets/images/insta.png";

import facebookOriginal from "../assets/images/facebookOriginal.png";
import youtubeOriginal from "../assets/images/youtubeOriginal.png";
import instagramOriginal from "../assets/images/instagramOriginal.png";

// import twitter from "../assets/images/timg.png";
import youtube from "../assets/images/youtube.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import MenuImg from "../assets/images/menu.png";

export default function Header() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="header_section p-0">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-4 col-md-4"></div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 text-center">
              <Link to="/">
                <img src={MainLogo} alt="" className="MainLogo" />
              </Link>
            </div> */}
            <div className="col-xl-2 col-lg-3 d-flex align-items-center PhoneDiv">
              <p><i class="fa-solid fa-phone"></i> (+91) 799 799 5252</p>
            </div>
            <div className="col-lg-7 col-md-9 col-sm-8 col-6 text-center">
            <Link to="/">
                <img src={mainLogoStrip} alt="" className="StripLogo" />
              </Link>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 d-flex align-items-center justify-content-end">
              <div>
                  <span className="displaySocialMediaLinks">
                    <span className="ms-3"><Link target="_blank" to="https://www.facebook.com/people/Sri-SPR-Global-School/61556127309565/"><img src={facebook} alt="facebook" width="25px" /></Link></span>
                    <span className="ms-3"><Link target="_blank" to="https://www.instagram.com/srisprglobal/"><img src={instagram} alt="instagram" width="20px" /></Link></span>
                    <span className="ms-3"><Link target="_blank" to="https://www.youtube.com/@SriSPRGlobalSchool"><img src={youtube} alt="youtube" width="23px" /></Link></span></span>
                  <span className="MenuTxt" onClick={handleShow}>
                    <img
                      src={MenuImg}
                      width="70%"
                      className="MenuImg"
                      alt=""
                    />
                </span>
              </div>
            </div>
            <div className="col-lg-12 col-md-4 col-sm-4 col-6 d-flex align-items-center justify-content-end d-none">
              <div>
                <span className="displaySocialMediaLinks">
                  <span className="ms-3"><Link target="_blank" to="https://www.facebook.com/people/Sri-SPR-Global-School/61556127309565/"><img src={facebook} alt="facebook" width="25px" /></Link></span>
                  <span className="ms-3"><Link target="_blank" to="https://www.instagram.com/srisprglobal/"><img src={instagram} alt="instagram" width="20px" /></Link></span>
                  <span className="ms-3"><Link target="_blank" to="https://www.youtube.com/@SriSPRGlobalSchool"><img src={youtube} alt="youtube" width="23px" /></Link></span></span>
                <span className="MenuTxt" onClick={handleShow}>
                  <img
                    src={MenuImg}
                    width="70%"
                    className="MenuImg"
                    alt=""
                  />
                </span>
              </div>
              <div>
                <Offcanvas
                  show={show}
                  onHide={handleClose}
                  className="offCanvass"
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <div className="container offcanvasContainer">
                      <div className="row text-end">
                        <div className="col-12 mt-4">
                          <Link
                            className="Links"
                            to="/"
                            onClick={handleClose}
                          >
                            Home
                          </Link>
                        </div>

                        <div className="col-12 mt-4">
                          <Link
                            className="Links"
                            to="about-us"
                            onClick={handleClose}
                          >
                            About
                          </Link>
                        </div>
                        <div className="col-12 mt-4">
                          <Link
                            className="Links"
                            to="/academics"
                            onClick={handleClose}
                          >
                            Academics
                          </Link>
                        </div>
                        <div className="col-12 mt-4">
                          <Link
                            className="Links"
                            to="admissions"
                            onClick={handleClose}
                          >
                            Admissions
                          </Link>
                        </div>
                        <div className="col-12 mt-4">
                          <Link
                            className="Links"
                            to="life-spr-school"
                            onClick={handleClose}
                          >
                            Life@SriSPRSchool
                          </Link>
                        </div>
                        <div className="col-12 mt-4">
                          <Link
                            className="Links"
                            to="/gallery"
                            onClick={handleClose}
                          >
                            Gallery
                          </Link>
                        </div>

                        <div className="col-12 mt-4">
                          <Link className="Links" to="contact-us" onClick={handleClose}>
                            Contact
                          </Link>
                        </div>

                        <div className="col-12 mt-4">
                          <h5>Connect</h5>
                          <span>
                            <Link target="_blank" to="https://www.facebook.com/people/Sri-SPR-Global-School/61556127309565/">
                              {" "}
                              <img src={facebookOriginal} className="me-2" alt="" />
                            </Link>{" "}
                            <Link target="_blank" to="https://www.instagram.com/srisprglobal/">
                              {" "}
                              <img
                                src={instagramOriginal}
                                width="20px"
                                className="me-2"
                                alt=""
                              />
                            </Link>{" "}
                            <Link target="_blank" to="https://www.youtube.com/@SriSPRGlobalSchool">
                              {" "}
                              <img src={youtubeOriginal} className="me-2" alt="" />
                            </Link>{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </div>
          </div>
        </div>
        <Navbar expand="md" className="MainNavBar">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="">
                <ul className="NavUl">
                  <li className="navLi d-flex align-items-center rightStatLi">
                    <Link className="Links " to="/">
                      Home
                    </Link>
                  </li>
                  <li className="navLi rightEndLi">
                    <Link className="Links" to="about-us">
                      About
                    </Link>
                  </li>
                  <li className="navLi rightEndLi">
                    <Link className="Links" to="/academics">
                      Academics
                    </Link>
                  </li>
                  <li className="navLi rightEndLi">
                    <Link className="Links" to="admissions">
                      Admissions
                    </Link>
                  </li>
                  <li className="navLi rightEndLi">
                    <Link className="Links" to="life-spr-school">
                      Life@SriSPRSchools
                    </Link>
                  </li>
                  <li className="navLi rightEndLi">
                    <Link className="Links" to="gallery">
                      Gallery
                    </Link>
                  </li>
                  <li className="navLi rightEndLi">
                    <Link className="Links" to="contact-us">
                      Contact
                    </Link>
                  </li>
                </ul>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </section>
    </>
  );
}




