import axios from "axios";

// export const apiUrl = "http://localhost:9000/api/v1";
export const apiUrl = "https://api.srisprglobalschool.com/api/v1";

export const postApi = async (url, data) => {
    try {
        const fullUrl = apiUrl + url;
        const response = await axios.post(fullUrl, data);
        return response.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}


export const getApi = async (url) => {
    try {
        const fullUrl = apiUrl + url;
        const response = await axios.get(fullUrl);
        return response.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}