import React from "react";
import MainLogo from "../assets/images/sprLogo.png";
import facebookOriginal from "../assets/images/facebookOriginal.png";
import youtubeOriginal from "../assets/images/youtubeOriginal.png";
import instagramOriginal from "../assets/images/instagramOriginal.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <section className="footer_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 mt-5">
              <img src={MainLogo} width="25%" className="MainLogo" alt="MainLogo" />

              <p className="mt-3">
              Our mission is to provide a safe, inclusive, and stimulating environment where students can explore their interests, develop their talents, and achieve academic excellence.
              </p>
            </div>

            <div className="col-lg-4 col-md-6 mt-5 text-center">
              <h6>Company</h6>
              <p className="pt-2">
                {" "}
                <Link className="menu_items" to="/">
                  Home
                </Link>{" "}
              </p>
              <p className="pt-2">
                {" "}
                <Link className="menu_items" to="/about-us">
                  About
                </Link>{" "}
              </p>
              <p className="pt-2">
                {" "}
                <Link className="menu_items" to="/academics">
                  Academics
                </Link>{" "}
              </p>
              <p className="pt-2">
                {" "}
                <Link className="menu_items" to="/admissions">
                  Admissions
                </Link>{" "}
              </p>
              <p className="pt-2">
                {" "}
                <Link className="menu_items" to="/gallery">
                  Gallery
                </Link>{" "}
              </p>
              <p className="pt-2">
                {" "}
                <Link className="menu_items" to="/contact-us">
                  Contact
                </Link>{" "}
              </p>
              <p className="pt-2">
                {" "}
                <Link className="menu_items" to="/life-spr-school">
                  Life@SriSPRSchools
                </Link>{" "}
              </p>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <h6 >Get In Touch</h6>
              <p className="pt-2">
              Sri SPR Global School, Chittaramma Devi Nagar, Gajularamaram, Hyderabad, Telandana - 500055 <br />
                <span className="phone_num">Phone: <a href="tel:+917997995252">(+91) 799 799 5252</a></span>
              </p>
              <div className="col-12 mt-3">
                  <span>
                    <span className="ContectTxt">Connect</span>
                    <Link target="_blank" to="https://www.facebook.com/people/Sri-SPR-Global-School/61556127309565/">
                      {" "}
                      <img src={facebookOriginal} className="me-2" alt="" />
                    </Link>{" "}
                    <Link target="_blank" to="https://www.instagram.com/srisprglobal/">
                      {" "}
                      <img
                        src={instagramOriginal}
                        width="20px"
                        className="me-2"
                        alt=""
                      />
                    </Link>{" "}
                     
                    <Link target="_blank" to="https://www.youtube.com/@SriSPRGlobalSchool">
                      {" "}
                      <img src={youtubeOriginal} className="me-2" alt="" />
                    </Link>{" "}
                  </span>
                </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12  d-flex align-items-center justify-content-center  mt-4">
              <div>
                <p>Copyright © 2024 Sri SPR Global School. All rights reserved</p>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-5 col-sm-12 col-12 ">
              <div className="row pt-3">
                <div className="col-12">
                  <span>
                    <span className="ContectTxt">Connect</span>
                    <Link target="_blank" to="https://www.facebook.com/people/Sri-SPR-Global-School/61556127309565/">
                      {" "}
                      <img src={facebookOriginal} className="me-2" alt="" />
                    </Link>{" "}
                    <Link target="_blank" to="https://www.instagram.com/srisprglobal/">
                      {" "}
                      <img
                        src={instagramOriginal}
                        width="20px"
                        className="me-2"
                        alt=""
                      />
                    </Link>{" "}
                     
                    <Link target="_blank" to="https://www.youtube.com/@SriSPRGlobalSchool">
                      {" "}
                      <img src={youtubeOriginal} className="me-2" alt="" />
                    </Link>{" "}
                  </span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
