import React from 'react'
import aboutus from '../../assets/images/home/aboutus.jpg';
import { Link } from 'react-router-dom';
export default function AboutUs() {
    return (
        <>
            <section className='HomeAboutUsSec'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div>
                            <p className='subHeading'>Sri SPR Global School</p>
                            <h2 className='pt-2'>About Us</h2>
                            <p className='pt-3'>Welcome to Sri SPR Global School, a nurturing and vibrant community that caters to students from Preschool to 10th grade. Our mission is to provide a comprehensive and engaging education that fosters the intellectual, emotional, and social growth of every child. We believe in a holistic approach to learning, where academics, arts, sports, and character development are equally valued. Our dedicated teachers and staff are committed to creating a supportive environment that inspires curiosity, creativity, and a love for learning. Join us on this exciting journey and watch your child flourish academically and personally.</p>
                            <Link to={'/about-us'}><button className='allBtns mt-4'>MORE DETAILS</button></Link>
                            </div>
                        </div>
                        <div className="col-lg-6 rightCol">
                            <div className='text-lg-end text-center pt-lg-0 pt-5'>
                                <img src={aboutus} width="80%"  className='rounded' alt=""/>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    )
}
