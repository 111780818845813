import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getApi } from "../../utilits/helpers/ApiServices";

const getYouTubeEmbedUrl = (url) => {
    const videoId = url.split('v=')[1];
    const ampersandPosition = videoId.indexOf('&');
    return ampersandPosition !== -1
        ? `https://www.youtube.com/embed/${videoId.substring(0, ampersandPosition)}`
        : `https://www.youtube.com/embed/${videoId}`;
};

export default function Events() {

    const [videos, setVideos] = useState([]);
    const fetchEventsData = async () => {
        try {
            const res = await getApi("/events/get");
            const videoTags = res.response.data.flatMap(event => event.video_tags.map(getYouTubeEmbedUrl));
            setVideos(videoTags.slice(0, 3));
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchEventsData();
    })
    return (
        <>
            <section className='EventsSec commmonSecSeperate'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="row">
                                <p className="subHeading">Showcasing Our School's Vibrant Life</p>
                                <h2 className='pt-2'>Life@SriSPRSchool </h2>
                                <p className='pt-2'>We will showcase below the exciting events and activities happening at our school. From academic competitions and sports tournaments to cultural festivals and community service projects, you'll see the dynamic and enriching experiences that make our school a place of growth and discovery.</p>
                                
                                {videos.map((video, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 pt-3" key={index}>
                                        <iframe
                                            width="100%"
                                            height="250"
                                            src={video}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                ))}
                                 
                                <div className="col-12 text-center mt-5">
                                    <Link to='/life-spr-school'>
                                        <button className='allBtns'>All Events</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
