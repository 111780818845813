import React from 'react';
import academics from '../../assets/images/home/academicsImg.jpg';
import academicsLogo from '../../assets/images/home/academics.png';
import admissions from '../../assets/images/home/admissionImg.jpg';
import admissionsLogo from '../../assets/images/home/admission.png';
import { Link } from 'react-router-dom';

export default function Academics() {
    return (
        <>
            <section className='academicsSec'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="card border-0">
                                        <div className="container">
                                            <div className="row pt-2 pb-3">
                                                <div className="col-12">
                                                    <img src={academics} width="100%" height="250px" alt="" />
                                                </div>
                                                <div className="col-12 py-4 px-3">
                                                    <h5>ACADEMICS</h5>
                                                    <p className='pt-2'>We assure quality in teaching, academic environment, and research strength through our state of the art infrastructural facilities.</p>
                                                </div>
                                                <div className="col-12 pt-2">
                                                <Link to="/academics">
                                                <button className='readMoreBtn'>Read More</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='badgeLogoDiv'>
                                            <img src={academicsLogo}  width="75%" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="card border-0">
                                        <div className="container">
                                            <div className="row pt-2 pb-3">
                                                <div className="col-12">
                                                    <img src={admissions} width="100%" height="250px" alt="" />
                                                </div>
                                                <div className="col-12 py-4 px-3">
                                                    <h5>ADMISSIONS</h5>
                                                    <p className='pt-2'>Admissions for the next academic year are now open! Enrol your child at Sri SPR Global School and be part of Excellence for their holistic development.</p>
                                                </div>
                                                <div className="col-12 pt-2">
                                                <Link to="/admissions">
                                                <button className='readMoreBtn'>Read More</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='badgeLogoDiv'>
                                            <img src={admissionsLogo} width="65%"  alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
