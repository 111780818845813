import React from 'react';
import { Link } from 'react-router-dom';

export default function VisionMission() {
    return (
        <>
            <section className='visionMissionSec commmonSecSeperate'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 commonMAinCol order-1 order-lg-1">
                            <div className="commonDiv">
                                <div className='visionCard'></div>
                                <div className='VisionColorDiv commoncolorDiv'>
                                    <div className="container">
                                        <div className="row px-3 py-5">
                                            <div className="col-12">
                                                <h5>Vision</h5>
                                                <p className='pt-4'>Empowering Students to be tomorrow's leaders with a foundation of moral integrity and academic excellence. Nurturing students into individuals who will positively impact society globally</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 commonMAinCol order-3 order-lg-2">
                            <div className="commonDiv">
                                <div className='artCraftCard'></div>
                                <div className='artCraftColorDiv commoncolorDiv'>
                                    <div className="container">
                                        <div className="row px-3 py-5">
                                            <div className="col-12">
                                                <h5>Mission</h5>
                                                <p className='pt-4'>Our mission is to provide a Holistic education that instills moral values develops critical thinking, nurtures creativity and equips students with the skills and mindset to excel in a rapidly changing world.</p>
                                            </div>
                                            <div className='pt-5 mt-3 d-none'>
                                                <Link to="contact-us"><i className='fas fa-arrow-right me-2'></i>Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 commonMAinCol order-2 order-lg-3">
                            <div className="commonDiv ">
                                <div className='curriculumColorDiv commoncolorDiv order-2 order-sm-1'>
                                    <div className="container">
                                        <div className="row px-3 py-5">
                                            <div className="col-12">
                                                <h5>Curriculum</h5>
                                                <p className='pt-4'>we emphasize on the core values of Empathy, Righteousness. Secularism.
                                                    Co-operation and Acceptance.</p>
                                            </div>
                                             
                                        </div>
                                    </div>
                                </div>
                                <div className='curriculumCard order-1 order-sm-2'></div>
                            </div>
                        </div>
                        <div className="col-lg-6 commonMAinCol order-4 order-lg-4">
                            <div className="commonDiv ">
                                <div className='LeadershipColorDiv commoncolorDiv order-2 order-sm-1'>
                                    <div className="container">
                                        <div className="row px-3 py-5">
                                            <div className="col-12">
                                                <h5>Motto</h5>
                                                <p className='pt-4'>Shiksha, Sanskriti, Samriddhi: 3S - Nurturing young Minds, Inspiring Innovation, Empowering Global Citizens.</p>
                                            </div>
                                             
                                        </div>
                                    </div>
                                </div>
                                <div className='LeadershipCard order-1 order-sm-2'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
