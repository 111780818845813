import React from 'react'
import MainLogo from "../assets/images/sprLogo.png";

const ComingSoon = () => {
  return (
    <>
      <section className='comingSoonSec p-0'>
            <div class="container-fluid p-0">
                        <div class="row">
                            <div class="col-12 p-0">
                                <div class="card img-fluid border-0 helloCard">
                                    <div class="card-img-overlay p-0">
                                        <div class="conatiner sticky-top trans_header pt-3 text-center ">
                                        </div>
                                        <div class="card-img-overlay p-0 d-flex align-items-center justify-content-center">
                                            <div class="conatiner px-3 text-center">
                                                <img src={MainLogo} alt="" width="20%" className="MainLogo" />
                                                <h3 class=" text-center mt-3"> OUR VISION </h3>
                                                <p class="text-center  pt-2">"Empowering students to be tomorrow's
                                                    leaders with a foundation of moral integrity and academic
                                                    excellence. <br />
                                                     Nurturing students into individuals who will positively impact
                                                    society globally."</p>
                                                <h2>Stay tuned: New school website launching soon!</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   
            </div>
      </section>
    </>
  )
}

export default ComingSoon
